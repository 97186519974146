<template>
  <div class="home">
    <home-cards
    ></home-cards>
  </div>
</template>

<script>

import HomeCards from "../components/dashboard/HomeCards";
export default {
  name: 'home',
  components: {HomeCards}
}
</script>
